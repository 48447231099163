import Page     from '../../page.js'
import template from './../../../../templates/pages/nested/deep/b.dot'

export let path = 'nested/deep/b'

export let create = () => {

    let p = new Page( {
        
        path: path,

        is_instance: false,

        template: template,

        nodes_single: {
        },
        
        nodes_array: {
        },

    } )

    return p

}