import template  from './../../templates/components/nested.dot'
import Component from './component.js'

export let name = 'nested'

export let create = () => {

    let c = new Component( {
    
        name: name,
    
        is_instance: false,
    
        template: template,
        
        nodes_single: {},
        
        nodes_array: {},
        
    } )
    
    return c

}