import Page     from '../page.js'
import template from './../../../templates/pages/nested/a.dot'

export let path = 'nested/a'

export let create = () => {

    let p = new Page( {
        
        path: path,

        is_instance: false,

        template: template,

        nodes_single: {
        },
        
        nodes_array: {
        },

    } )

    return p

}