import * as content     from '../core/content.js'
import { gsap, Power2 } from 'gsap/index.js'
import * as components  from './../components/index.js'
import * as utils       from './../core/utils.js'

export default class Page {

    constructor( data ) {

        // DEFAULTS
        this.id = utils.uuid()
        this.loaded = false
        this.el = null
        this.nodes = {}
        this.components = {}
        this.nodes_single = []
        this.nodes_array = []
        this.components_list = {}

        // REQUIRED
        const required_params = [ 'path', 'template', 'nodes_single', 'nodes_array' ]
        required_params.forEach( p => {
            if ( data[ p ] !== undefined ) {
                this[ p ] = data[ p ]
            }
            else {
                console.error( 'ERROR: Page missing required parameter in constructor data: ', p )
            }
        } )

        // OVERRIDES
        for ( let param in data ) {
            this[ param ] = data[ param ]
        }
        
    }

    // DEFAULT fetch
    async fetch( /*query*/ ) {

        return new Promise( ( resolve ) => {
            
            let parts = this.path.split( '/' )
            
            if ( parts.length === 1 ) {
                this.data = content.all.pages[ this.path ]
            }
            else {
                // WALK down the nested path
                let data = content.all.pages
                parts.forEach( ( x ) => { data = data[ x ] } )
                this.data = data
            }

            this.fetched = true

            resolve()

        } )
        
    }

    on_build() {
        // nothing
    }

    log( message1, message2 ) {
        if ( message2 ) {
            console.log( `${ this.name }:`, message1, message2 )
        }
        else {
            console.log( `${ this.name }:`, message1 )
        }
    }

    build( options ) {
        
        if ( options.parent_el === undefined ) {
            console.error( 'ERROR Missing parameter "parent_el" in parameters for build fn' )
        }
        this.parent_el = options.parent_el

        let el = document.createElement( 'div' )
        el.classList.add( 'container-inner' )
        let page_div = document.createElement( 'div' )
        page_div.classList.add( 'page' )
        page_div.classList.add( this.path.replaceAll( '/', '-' ) )
        page_div.innerHTML = this.template( this.data )
        el.appendChild( page_div )

        if ( options.classes ) {
            options.classes.forEach( c => {
                el.classList.add( c )
            } )
        }

        if ( window.is_static ) {
            el.classList.add( 'static' )
        }
        else {
            el.classList.add( 'hidden' )
        }

        this.parent_el.appendChild( el )
        
        this.nodes.container = el
        this.nodes.page_div = page_div

        this.get_nodes()

        this.on_build()
        
    }

    get_nodes() {

        for ( let n in this.nodes_single ) {
            this.nodes[ n ] = this.qs( this.nodes_single[ n ] )
        }

        for ( let n in this.nodes_array ) {
            this.nodes[ n ] = this.qsa( this.nodes_array [ n ] )
        }

        // console.log( `%c nodes | ${ this.path } `, 'background: #F9704B; color: #fff', this.nodes )
    }

    qs( selector ) {
        return this.nodes.page_div.querySelector( selector )
    }

    qsa( selector ) {
        return Array.prototype.slice.call( this.nodes.page_div.querySelectorAll( selector ) )
    }

    async add_all_components() {

        let ps = []
        for ( let name in this.components_list ) {
            let parent_el = this.nodes[ this.components_list[ name ].parent_el ]
            let data = this.components_list[ name ].data
            ps.push( this.add_component( name, parent_el, data ) )
        }
        await Promise.all( ps )
        
    }

    async add_component( name, parent_el, override_data ) {
        
        let c = components.all[ name ]()

        await c.fetch()
        
        c.build( {
            parent_el: parent_el,
            override_data: override_data,
        } )
        
        this.components[ name ] = c

    }

    async show( other, reverse ) {
        
        let self_tween = this.tween_in( other, reverse )
        
        // CREATE all sub components
        await this.add_all_components()

        // TWEEN IN all sub components
        let components_tweens = []
        for ( let c in this.components ) {
            components_tweens.push( this.components[ c ].show() )
        }
        await Promise.all( [ ...components_tweens, self_tween ] )
        
    }

    async tween_in( other, reverse ) {
        
        return new Promise( ( resolve ) => {
            
            let dir = reverse ? -1 : 1

            let tl = gsap.timeline( {
                paused: true,
                onStart: () => {
                    this.nodes.container.classList.remove( 'hidden' )
                },
                onComplete: async () => {
                    
                    // CLEAN up
                    tl.kill()
                    tl = null

                    //FINISH
                    resolve()
                    
                },
            } )
            
            tl.add( gsap.fromTo(
                this.nodes.page_div,
                { opacity: 0, y: 10 },
                { opacity: 1, y: 0, ease: Power2.easeOut, duration: 0.25 },
            ) )
            
            tl.play()

        } )
    }

    async hide( other, reverse ) {

        let self_tween = this.tween_out()
        
        // TWEEN OUT all sub components
        let components_tweens = []
        for ( let c in this.components ) {
            components_tweens.push( this.components[ c ].hide() )
        }

        await Promise.all( [ ...components_tweens, self_tween ] )
        
    }

    async tween_out( other, reverse ) {
        
        return new Promise( ( resolve ) => {

            let dir = reverse ? -1 : 1

            let tl = gsap.timeline( {
                paused: true,
                onStart: () => {
                },
                onComplete: () => {
                    this.nodes.container.classList.add( 'hidden' )

                    resolve()

                    tl.kill()
                    tl = null
                },
            } )

            tl.add( gsap.to(
                this.nodes.page_div,
                { opacity: 0, y: -10, ease: Power2.easeIn, duration: 0.25 },
            ) )

            tl.play()

        } )
        
    }

    destroy() {

        for ( let c in this.components ) {
            this.components[ c ].destroy()
        }
        
        this.on_destroy()

        this.nodes.container.remove()

    }

    on_destroy() {

    }

}