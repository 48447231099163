import * as home          from './home.js'
import * as about         from './about.js'
import * as gallery       from './gallery.js'
import * as error         from './error.js'
import * as nested_a      from './nested/a.js'
import * as nested_deep_b from './nested/deep/b.js'

export let all = {}

export let build = () => {
    
    register( home )
    register( about )
    register( gallery )
    register( error )
    register( nested_a )
    register( nested_deep_b )
    
    //
    // ADD new pages here
    // 
    
    console.log( '%c pages ', 'background: #FFD086; color: #222', all )
    
}

export let register = ( p ) => {
    let slug = p.path.replaceAll( '/', '.' )
    all[ slug ] = p.create
}

export let get = ( path ) => {

    if ( all[ path ] !== undefined ) {
        return all[ path ]
    }
    else {
        return false
    }

}