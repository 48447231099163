import * as utils                    from './utils.js'
import mobile                        from './mobile.js'
import { default as reexport_state } from './state.js'
import { gsap, Power2 }              from 'gsap/index.js'
import { CustomEase }                from 'gsap/CustomEase.js'
import * as navigation               from './navigation.js'
import * as lazyload                 from './lazyload.js'
import * as qs                       from './qs.js'
import * as content                  from './content.js'
import * as pages                    from './../pages/index.js'
import * as components               from './../components/index.js'

gsap.registerPlugin( Power2, CustomEase )

export let build = async () => {

    if ( !content.all.loaded ) {

        // GET root level nodes
        get_nodes()

        // ADD resize handlers, and call right away
        window.addEventListener( 'resize', resize_handler )
        resize_handler()

        // BASELINE state
        populate_state()

        // GET any data from query string
        qs.parse()

        console.log(
            '%c state ', 'background: #FF1665; color: #fff',
            state.all,
        )
        
        // FETCH dynamic content
        {
            // Content has not been loaded yet. Go get it!
            
            // let response = await fetch( '/assets/content.json' )
            // let data = await response.json()
            // content.store( data )
        }
    
        // STATIC content ships with built app
        {
            content.build()
        }
        
        // Build app
        components.build()
        pages.build()
        navigation.build()
        lazyload.build()
        render_loop()
                
        // Load the app at our entry point
        await navigation.locate()

        await add_components()
        await show_components()
        
        // DEV query string params
        if ( process.env.NODE_ENV !== 'production' ) {
            qs.parse_dev()
        }
        
    }

}

export let resize_handler = () => {

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty( '--vh', `${vh}px` )

    let scroll_w = nodes.body.clientWidth - nodes.container.clientWidth
    document.documentElement.style.setProperty( '--scrollbar-width', `${scroll_w}px` )
    
    let w = window.innerWidth - scroll_w
    let h = window.innerHeight

    state.window_size =
        {
            w: w,
            h: h,
            aspect: w / h,
            d: Math.sqrt( Math.pow( w, 2 ) + Math.pow( h, 2 ) ),
            px: window.devicePixelRatio,
            u_px_norm: { x: w, y: h },
            aspect_vec: { x: 1.0, y: w / h },
            scroll_w: scroll_w,
            x: 0,
            y: 0,
            u: 1.0 / w,
            v: 1.0 / h,
        }

}

export let nodes = {}

export let get_nodes = () => {

    // SELECT rest of nodes via body
    nodes.body = document.querySelector( 'body' )
    nodes.dev_wrap = utils.qs( '.dev-wrap', nodes.body )
    nodes.container = utils.qs( '.container', nodes.body )
    nodes.svg_canvas_wrap = utils.qs( '.svg-canvas-wrap', nodes.body )
    
}

export let root_components = {}

let components_list = {
    'dev': { parent_el: 'dev_wrap', override_data: undefined },
    'svg-canvas': { parent_el: 'svg_canvas_wrap', override_data: undefined },
}

export let add_components = async () => {

    let ps = []
    for ( let name in components_list ) {
        let parent_el = nodes[ components_list[ name ].parent_el ]
        let override_data = components_list[ name ].override_data
        if ( name === 'dev' ) {
            if ( process.env.NODE_ENV !== 'production' ) {
                ps.push( add_component( name, parent_el, override_data ) )
            }
        }
        else {
            ps.push( add_component( name, parent_el, override_data ) )
        }
        
    }
    await Promise.all( ps )

}

let add_component = async ( name, parent_el, override_data ) => {
    
    let c = components.get( name )()

    await c.fetch()

    c.build( {
        parent_el: parent_el,
        override_data: override_data,
    } )
        
    root_components[ name ] = c
    
}

export let show_components = async () => {

    let components_tweens = []
    for ( let c in root_components ) {
        components_tweens.push( root_components[ c ].show() )
    }
    await Promise.all( components_tweens )

}

export let reset_scroll = () => {

    utils.qs( '.container' ).scrollTop = 0
    document.documentElement.scrollTop = 0
    window.scrollTo( 0, 0 )

}

let render_loop = () => {

    gsap.ticker.add( ( time, delta, frame, unknown ) => {
        state.time = {
            sec: time,
            frame: frame,
            delta: delta,
        }
    } )

}

// STATE object for tracking certain states across the app

export let state = reexport_state

export let populate_state = () => {

    // TEST for mobile via user agent string
    state.is_mobile = mobile()
    if ( state.is_mobile ) {
        nodes.body.classList.add( 'mobile' )
    }
    else {
        nodes.body.classList.add( 'not-mobile' )
    }

    state.time = {
        sec: 0,
        frame: 0,
        delta: 0,
    }

    state.show_dev = false

    state.label_1 = 'Hello world'

    state.color_1 = [ 0, 128, 255, 0.3 ]
    
    state.slider_1 = 0.5

    state.checkbox_1 = true

    // fast, medium, slow
    state.dropdown_1 = 'fast'

    state.dropdown_key_1 = 1.0

}