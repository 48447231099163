import * as content     from '../core/content.js'
import { gsap, Power2 } from 'gsap/index.js'
import * as components  from './index.js'
import * as utils       from './../core/utils.js'

export default class Component {

    constructor( data ) {

        // DEFAULTS
        this.id = utils.uuid()
        this.fetched = false
        this.is_instance = false
        this.parent_el = null
        this.nodes = {}
        this.components = {}
        this.nodes_single = []
        this.nodes_array = []

        // REQUIRED
        const required_params = [ 'name', 'template', 'nodes_single', 'nodes_array' ]
        required_params.forEach( p => {
            if ( data[ p ] !== undefined ) {
                this[ p ] = data[ p ]
            }
            else {
                console.error( 'ERROR: Component missing required parameter in constructor data: ', p )
            }
        } )
        
        // OVERRIDES
        for ( let param in data ) {
            this[ param ] = data[ param ]
        }

    }

    // DEFAULT fetch
    async fetch( /*query*/ ) {

        return new Promise( ( resolve ) => {
            
            this.data = content.all.components[ this.name ]
 
            this.fetched = true

            resolve()

        } )
        
    }

    on_build() {
        // nothing
    }

    log( message1, message2 ) {
        if ( message2 ) {
            console.log( `${ this.name }:`, message1, message2 )
        }
        else {
            console.log( `${ this.name }:`, message1 )
        }
    }

    build( options ) {
        
        if ( !options.parent_el ) {
            console.error( 'ERROR Missing parameter "parent_el" in parameters for build fn' )
        }
        this.parent_el = options.parent_el

        let el = document.createElement( 'div' )
        el.classList.add( 'component-wrap' )
        el.classList.add( this.name )
        el.innerHTML = this.template( this.data )
        el.children[ 0 ].classList.add( 'component-inner' )
        
        if ( options.classes ) {
            options.classes.forEach( c => {
                el.classList.add( c )
            } )
        }

        if ( window.is_static ) {
            el.classList.add( 'static' )
        }
        else {
            el.classList.add( 'hidden' )
        }

        this.parent_el.innerHTML = ''
        this.parent_el.style.display = 'flex'
        this.parent_el.appendChild( el )

        this.nodes.container = el

        this.get_nodes()

        this.add_all_components()

        this.on_build()

    }

    get_nodes() {

        for ( let n in this.nodes_single ) {
            this.nodes[ n ] = this.qs( this.nodes_single[ n ] )
        }

        for ( let n in this.nodes_array ) {
            this.nodes[ n ] = this.qsa( this.nodes_array [ n ] )
        }

        // console.log( `%c components | ${ this.name } `, 'background: #F9704B; color: #fff', this.nodes )
    }

    qs( selector ) {
        return this.nodes.container.querySelector( selector )
    }

    qsa( selector ) {
        return Array.prototype.slice.call( this.nodes.container.querySelectorAll( selector ) )
    }

    async add_all_components() {

        let ps = []
        for ( let name in this.components_list ) {
            let parent_el = this.nodes[ this.components_list[ name ].parent_el ]
            let data = this.components_list[ name ].data
            ps.push( this.add_component( name, parent_el, data ) )
        }
        await Promise.all( ps )
        
    }

    async add_component( name, parent_el, override_data ) {
        
        let c = components.all[ name ]()

        await c.fetch()
        
        c.build( {
            parent_el: parent_el,
            override_data: override_data,
        } )
        
        this.components[ name ] = c

    }

    async show() {

        let self_tween = this.tween_in()
        
        // CREATE all sub components
        await this.add_all_components()

        // TWEEN IN all sub components
        let components_tweens = []
        for ( let c in this.components ) {
            components_tweens.push( this.components[ c ].show() )
        }
        
        await Promise.all( [ ...components_tweens, self_tween ] )
        
    }

    async tween_in() {

        return new Promise( ( resolve ) => {

            let tl = gsap.timeline( {
                paused: true,
                onStart: () => {
                    this.nodes.container.classList.remove( 'hidden' )
                },
                onComplete: async () => {
                    
                    // CLEAN up
                    tl.kill()
                    tl = null

                    //FINISH
                    resolve()

                },
            } )
            
            tl.add( gsap.fromTo(
                this.nodes.container,
                { opacity: 0, y: 10 },
                { opacity: 1, y: 0, ease: Power2.easeOut, duration: 0.25 },
            ) )
            
            tl.play()

        } )
        
    }

    async hide() {
    
        let self_tween = this.tween_out()
        
        // TWEEN OUT all sub components
        let components_tweens = []
        for ( let c in this.components ) {
            components_tweens.push( this.components[ c ].hide() )
        }

        await Promise.all( [ ...components_tweens, self_tween ] )
        
    }

    async tween_out() {
        
        return new Promise( ( resolve ) => {

            let tl = gsap.timeline( {
                paused: true,
                onStart: () => {
                },
                onComplete: () => {

                    this.nodes.container.classList.add( 'hidden' )

                    resolve()

                    tl.kill()
                    tl = null
                },
            } )

            tl.add( gsap.to(
                this.nodes.container,
                { opacity: 0, y: -10, ease: Power2.easeIn, duration: 0.5 },
            ) )

            tl.play()
            
        } )
        
    }

    on_destroy() {
        // nothing here
    }

    destroy() {

        for ( let c in this.components ) {
            this.components[ c ].destroy()
        }
        
        this.on_destroy()

        this.nodes.container.remove()

    }

}