<div class="component">

    <div class="header-row">

        <div class="header"> {{= it.header }} </div>

        <div class="close">
            {{#def.loadfile("./src/assets/svgs/close.svg") }}
        </div>
    </div>

    <div class="gui-wrap"> </div>

</div>