import Page     from './page.js'
import template from './../../templates/pages/about.dot'

export let path = 'about'

export let create = () => {

    let p = new Page( {
        
        path: path,

        is_instance: false,

        template: template,

        nodes_single: {
            'header': '.header',
            'body': '.body',
            'stats_wrap': '.stats-wrap',
        },

        nodes_array: {
            'buttons': '.button',
        },

        components_list: {
            'stats': { parent_el: 'stats_wrap' },
        },
        
    } )

    return p

}