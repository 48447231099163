import Component                from './component.js'
import template                 from './../../templates/components/svg-canvas.dot'
import { gsap, Power2, Power0 } from 'gsap/index.js'
import { state }                from './../core/controller.js'

/*

    Copy paste this shiz when creating a new page

*/

export let name = 'svg-canvas'

export let create = () => {

    let c = new Component( {
        
        name: name,

        is_instance: false,

        template: template,

        nodes_single: {
            'canvas': '.canvas',
            'group': '.canvas g.all',
        },

        nodes_array: {
        },

        components_list: {},

        shapes: [],

        on_build: function () {

            state.watch(
                'window_size',
                ( data ) => { this.on_resize( data ) },
                this.id,
            )

            state.watch(
                'time',
                ( data ) => { this.on_update( data ) },
                this.id,
            )

            this.on_resize( state.window_size )
            let padding = 20

            let p1 = new Point( { x: -state.window_size.w * 0.5 + padding, y: -state.window_size.h * 0.5 + padding } )
            let p2 = new Point( { x: state.window_size.w * 0.5 - padding, y: state.window_size.h * 0.5 - padding } )
            let line = new Line( { p1: p1, p2: p2 } )
            
            this.nodes.group.appendChild( line.svg )
            
            this.shapes.push( line )
            
            this.spin_tl = gsap.timeline( {
                paused: true,
                repeat: -1,
                yoyo: true,
            } )

            this.spin_tl.add( gsap.to(
                this.shapes[ 0 ].p1,
                { x: state.window_size.w * 0.5 - padding, ease: Power2.easeInOut, duration: 5 },
            ), 0 )
            
            this.spin_tl.add( gsap.to(
                this.shapes[ 0 ].p2,
                { x: -state.window_size.w * 0.5 + padding, ease: Power2.easeInOut, duration: 5 },
            ), 0 )
            
            this.spin_tl.play()

        },

        on_destroy: function () {
            
            state.unwatch(
                'window_size',
                this.id,
            )

            state.unwatch(
                'frame',
                this.id,
            )

        },

        on_resize: function () {
            this.nodes.canvas.setAttribute( 'width', state.window_size.w )
            this.nodes.canvas.setAttribute( 'height', state.window_size.h )
            this.nodes.canvas.setAttribute( 'viewBox', `0 0 ${ state.window_size.w } ${ state.window_size.h }` )
        },

        fetch: async function ( query ) {
            
            return new Promise( ( resolve ) => {

                this.data = {}

                this.fetched = true

                resolve()

            } )
        },

        tween_in: async function ( other, reverse ) {
        
            return new Promise( ( resolve ) => {
            
                let dir = reverse ? -1 : 1

                let tl = gsap.timeline( {
                    paused: true,
                    onStart: () => {
                        this.nodes.container.classList.remove( 'hidden' )
                    },
                    onComplete: async () => {
                    
                        // CLEAN up
                        tl.kill()
                        tl = null

                        //FINISH
                        resolve()
                    
                    },
                } )
            
                tl.add( gsap.fromTo(
                    this.nodes.container,
                    { opacity: 0, y: 10 },
                    { opacity: 1, y: 0, ease: Power2.easeOut, duration: 0.5 },
                ) )
            
                tl.play()

            } )
        },

        tween_out: async function( other, reverse ) {
        
            return new Promise( ( resolve ) => {

                let dir = reverse ? -1 : 1

                let tl = gsap.timeline( {
                    paused: true,
                    onStart: () => {
                    },
                    onComplete: () => {
                        this.nodes.container.classList.add( 'hidden' )

                        resolve()

                        tl.kill()
                        tl = null
                    },
                } )

                tl.add( gsap.to(
                    this.nodes.container,
                    { opacity: 0, y: -10, ease: Power2.easeIn, duration: 0.25 },
                ) )

                tl.play()

            } )
            
        },
        
        on_update( time ) {
            
            // console.log( time.frame )

            this.shapes.forEach( s => {
                s.update()
            } )

        },
    
    } )

    return c

}

class Point {

    constructor( data ) {
        this.x = data.x ? data.x : 0
        this.y = data.y ? data.y : 0
    }

    // CONVERT from top left origin coordinate space
    from_svg_space( data ) {
        if ( data === undefined ) {
            this.x = this.x - state.window_size.w * 0.5
            this.y = this.y - state.window_size.h * 0.5
        }
        else {
            this.x = data.x - state.window_size.w * 0.5
            this.y = data.y - state.window_size.h * 0.5
        }
    }

    // CONVERT to top left origin coordinate space
    to_svg_space() {
        return {
            x: this.x + state.window_size.w * 0.5,
            y: this.y + state.window_size.h * 0.5,
        }
    }

}

class Line {

    constructor( data ) {
        
        this.p1 = data.p1
        this.p2 = data.p2

        this.stroke_width = 3
        this.stroke_color = '#FFFFFF'
        this.stroke_linecap = 'round'
        this.opacity = 1
        this.classes = data.classes ? data.classes : []

        this.svg = document.createElementNS( 'http://www.w3.org/2000/svg', 'line' )
        // `<line x1="0" y1="0" x2="0" y2="0" stroke-width="${this.stroke_width}" stroke="${this.stroke_color}" stroke-linecap="${this.stroke_linecap}" opacity="${this.opacity}"/>`

        this.classes.forEach( c => {
            this.svg.classList.add( c )
        } )
        
        this.update()
        
    }

    update() {

        let p1_svg = this.p1.to_svg_space()
        this.svg.setAttribute( 'x1', p1_svg.x )
        this.svg.setAttribute( 'y1', p1_svg.y )
        
        let p2_svg = this.p2.to_svg_space()
        this.svg.setAttribute( 'x2', p2_svg.x )
        this.svg.setAttribute( 'y2', p2_svg.y )
        
        this.svg.setAttribute( 'stroke-width', this.stroke_width )
        this.svg.setAttribute( 'stroke', this.stroke_color )
        this.svg.setAttribute( 'stroke-linecap', this.stroke_linecap )
        this.svg.setAttribute( 'opacity', this.opacity )
        
    }

}

// CALC the coordinates of the center of a DOM element where it is,
// and return in center origin uv space
let calc_center = ( node ) => {
    return {
        x: gsap.getProperty( node, 'x' ),
        y: gsap.getProperty( node, 'y' ),
    }
}