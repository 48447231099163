import Page      from './page.js'
import template  from './../../templates/pages/home.dot'
import { state } from './../core/controller.js'

export let path = 'home'

export let create = () => {

    let p = new Page( {
        
        path: path,

        is_instance: false,

        on_build: function() {

            state.watch(
                'label_1',
                ( data ) => { this.color_change( data ) },
                this.id,
            )

        },

        on_destroy: function() {

            state.unwatch( 'label_1', this.id )

        },

        template: template,

        nodes_single: {
            'header': '.header',
            'body': '.body',
            'stats_wrap': '.stats-wrap',
        },
        
        nodes_array: {
            'buttons': '.button',
        },

        components_list: {
            'stats': { parent_el: 'stats_wrap' },
        },

        color_change: function ( data ) {
            console.log( data )
        },

    } )
    
    return p

}