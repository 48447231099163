import Page         from './page.js'
import template     from './../../templates/pages/gallery.dot'
import * as content from './../core/content.js'

export let path = 'gallery'

export let create = () => {

    let p = new Page( {
        
        path: path,

        is_instance: true,

        template: template,

        nodes_single: {
        },
        
        nodes_array: {
        },

        fetch: async function ( query ) {
            return new Promise( ( resolve ) => {

                if ( content.all.pages.gallery[ query ] ) {
                    this.data = content.all.pages.gallery[ query ]
                }
                else {
                    this.data = {
                        body: 'Not found',
                    }
                }

                this.fetched = true

                resolve()

            } )
        },

    } )
    
    return p

}