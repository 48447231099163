<div class="wrap">

    <div class="header"> {{= it.content.header }} </div>

    <div class="body"> {{= it.content.body }} </div>

    <div class="buttons-row">
        <a class="button" href="/home" data-link="internal"> Home </a>
        <a class="button" href="/gallery/a" data-link="internal"> Gallery A </a>
        <a class="button" href="/gallery/b" data-link="internal"> Gallery B </a>
        <a class="button" href="/gallery/c" data-link="internal"> Gallery C </a>
        <a class="button" href="/about" data-link="internal"> About </a>
        <a class="button" href="/nested/a" data-link="internal"> Nested A </a>
        <a class="button" href="/nested/deep/b" data-link="internal"> Nested Deep B </a>
    </div>

</div>