import qs              from 'query-string'
import * as navigation from './navigation.js'
import { state }       from './controller.js'

export let parse = () => {

    let params = qs.parse( location.search )
    
    for ( let p in params ) {
        
        let v = params[ p ]

        // ADD production keys here
        if ( p === 'mode' ) {
            state.mode = v
            console.log( state.all )
        }

    }

}

const jump_pages = [
    '/gallery/a',
    'gallery/b',
    'about',
]

export let parse_dev = () => {

    let params = qs.parse( location.search )
    for ( let p in params ) {
        let v = params[ p ]

        // SHOW dev toggle
        if ( p == 'show_dev' ) {
            state.show_dev = ( v === '1' )
        }

        // JUMP to page
        if ( p == 'jump' && jump_pages.indexOf( v ) >= 0 ) {
            navigation.link_click( v )
        }

    }
}
