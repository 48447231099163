import template  from './../../templates/components/dev.dot'
import Component from './component.js'
import * as dat  from 'dat.gui'
import { state } from './../core/controller.js'

export let name = 'dev'

export let create = () => {

    let c = new Component( {
        
        name: name,

        is_instance: false,

        template: template,
        
        nodes_single: {
            'header': '.header',
            'gui_wrap': '.gui-wrap',
            'body': '.body',
            'close': '.close', 
        },

        nodes_array: {},

        on_build: function () {
            
            this.dat_gui = new dat.GUI( {
                name: 'My GUI',
                autoPlace: false,
                width: '100%',
                hideable: false,
            } )
    
            this.dat_gui.add( state, 'label_1', 0, 1 )

            this.dat_gui.addColor( state, 'color_1' )
                
            this.dat_gui.add( state, 'slider_1', 0, 1 )
                
            this.dat_gui.add( state, 'checkbox_1' )
                
            this.dat_gui.add( state, 'dropdown_1', [ 'fast', 'medium', 'slow' ] )
                
            this.dat_gui.add( state, 'dropdown_key_1', { 'low': 1, 'medium': 4, 'high': 8 } )
    
            this.dat_gui.add( this, 'restart' )
    
            this.nodes.gui_wrap.appendChild( this.dat_gui.domElement )

            // Keyboard listener
            // 
            // Control/Command + Alt + D
            // 
            window.addEventListener( 'keyup', ( e ) => {

                // Merge control (windows) and command (mac)
                let ctl = e.getModifierState( 'Control' ) || e.getModifierState( 'Meta' )

                if ( e.code == 'KeyD' && e.getModifierState( 'Alt' ) && ctl ){
                    state.show_dev = !state.show_dev
                }

            } )
    
            this.nodes.close.addEventListener( 'click', () => {    
                state.show_dev = false
            } )

            // APPLY default
            this.toggle( state.show_dev )
            
            state.watch(
                'show_dev',
                ( data ) => { this.toggle( data ) },
                this.id,
            )

        },

        on_destroy: function() {

            state.unwatch(
                'show_dev',
                this.id,
            )

        },

        show: async function() {

            return new Promise( ( resolve ) => {
        
                this.nodes.container.classList.remove( 'hidden' )
            
                resolve()

            } )

        },
        
        hide: async function() {

            return new Promise( ( resolve ) => {
    
                this.nodes.container.classList.add( 'hidden' )
        
                resolve()

            } )

        },

        toggle: function ( open ) {

            this.is_open = ( open !== undefined )
                ? open
                : !this.is_open
            
            this.nodes.container.classList.toggle( 'active', this.is_open )
            
        },
        
        restart: function () {
            console.log( 'restart trigger' )
        }, 

    } )
    
    return c

}