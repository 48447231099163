import * as dev        from './dev.js'
import * as stats      from './stats.js'
import * as nested     from './nested.js'
import * as svg_canvas from './svg-canvas.js'

export let all = {}

export let build = () => {
    
    register( dev )
    register( stats )
    register( nested )
    register( svg_canvas )
    
    //
    // ADD new components here
    // 
    
    console.log( '%c components ', 'background: #F9704B; color: #fff', all )
    
}

export let register = ( p ) => {
    all[ p.name ] = p.create
}

export let get = ( name ) => {

    if ( all[ name ] !== undefined ) {
        return all[ name ]
    }
    else {
        console.error( `Components: Cannot find component named ${ name }` )
        return false
    }

}