import * as utils from './utils.js'

const reserved = [
    'watch',
    'unwatch',
    'all',
]

let state = {

    watch: function ( key, action, _id ) {
        
        let id = _id ? _id : utils.uuid()

        if ( events[ key ] === undefined ) {
            events[ key ] = {}
        }

        events[ key ][ id ] = action
        
        log( `Add listener ${ key }, ${ id }` )

        return id

    },

    unwatch: function( key, id ) {
        
        // REMOVE the listener by id
        if ( events[ key ] && events[ key ][ id ] ) {
            
            events[ key ][ id ] = undefined
            delete events[ key ][ id ]
            log( `Remove listener ${key}, ${id}` )
            
        }
        else {

            let reason = ( events[ key ] === undefined )
                ? 'Key not found in state'
                : 'ID not found in watchers'
            
            log( `Cannot remove listener for ${key}, ${id}`, reason )
            
        }
        
    },

}

let events = {}

let proxy = new Proxy( state, {
    
    set: function ( target, key, value ) {
        
        if ( reserved.indexOf( key ) >= 0 ) {
            console.error( `ERROR, use of reserved key in state object: ${key}` )
            return
        }

        // SET the value
        target[ key ] = value
        
        // CALL the watch
        if ( events[ key ] ) {
            for ( let id in events[ key ] ) {
                if ( events[ key ][ id ] ) {
                    events[ key ][ id ]( value )
                }
                else {
                    target.off( key, id )
                }
            }
        }
        
        return true
    },

    get: ( target, property, receiver ) => {
        if ( property === 'all' ) {
            return utils.copy_obj( target )
        }
        return Reflect.get( target, property, receiver )
    },
    
} )

const log = ( message_1, message_2 ) => {
    if ( message_2 ) {
        console.log( '🔢 State:', message_1, message_2 )
    }
    else {
        console.log( '🔢 State:', message_1 )
    }
}

export default proxy