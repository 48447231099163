import template  from './../../templates/components/stats.dot'
import Component from './component.js'
import { state } from './../core/controller.js'

export let name = 'stats'

export let create = () => {

    let c = new Component( {
        
        name: name,        
        
        is_instance: false,

        template: template,
        
        on_build: function () {
            
            state.watch(
                'slider_1',
                ( data ) => { this.on_slider( data ) },
                this.id,
            )
            
            this.nodes.nested_wrap

        },

        on_slider: function ( data ) {
            this.log( 'slider_1', data )
        },

        on_destroy: function () {
            
            state.unwatch( 'slider_1', this.id )

        },

        nodes_single: {
            'nested_wrap': '.nested-wrap',
        },

        nodes_array: {},

        components_list: {
            'nested': { parent_el: 'nested_wrap' },
        },

    } )

    return c

}