{
    "pages": {
        "home": {
            "seo": {
                "title": "{{{ NAME }}}",
                "meta_name": {
                    "keywords": "{{{ KEYWORDS }}}",
                    "description": "{{{ DESCRIPTION }}}"
                },
                "meta_property": {
                    "og:type": "website",
                    "og:url": "{{{ URL }}}",
                    "og:image": "{{{ URL }}}/assets/images/og.jpg",
                    "og:image:width": "1200",
                    "og:image:height": "630",
                    "og:title": "{{{ NAME }}}",
                    "og:description": "{{{ DESCRIPTION }}}",
                    "og:site_name": "{{{ SITENAME }}}"
                }
            },
            "content": {
                "header": "Home hello world",
                "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            }
        },
        "about": {
            "seo": {
                "title": "{{{ NAME }}} | About",
                "meta_name": {
                    "keywords": "{{{ KEYWORDS }}}, About"
                },
                "meta_property": {
                    "og:title": "{{{ NAME }}} | About oggg"
                }
            },
            "content": {
                "header": "About hello world",
                "body": "Ut atque praesentium aut assumenda cupiditate eos veniam internos. Eum eaque nihil ut dolor laudantium est voluptatem dolorem in molestiae ducimus vel iure sint. Quo eligendi voluptatem et magnam nihil non fugiat repudiandae et vitae dolores et quia recusandae qui quia consequatur. Qui ipsam nisi et autem consequatur cum autem sint est iure repudiandae et enim consequatur est nobis totam."
            }
        },
        "gallery": {
            "a": {
                "seo": {
                    "meta_name": {},
                    "meta_property": {}
                },
                "content": {
                    "media": [
                        {
                            "uri": "/assets/images/pic-1.jpg",
                            "width": 1200,
                            "height": 900
                        },
                        {
                            "uri": "/assets/images/pic-2.jpg",
                            "width": 1200,
                            "height": 900
                        },
                        {
                            "uri": "https://player.vimeo.com/video/393795643",
                            "width": 1920,
                            "height": 1080
                        },
                        {
                            "uri": "/assets/images/video-1.mp4",
                            "width": 1920,
                            "height": 1080
                        },
                        {
                            "uri": "/assets/images/pic-3.jpg",
                            "width": 1200,
                            "height": 900
                        },
                        {
                            "uri": "https://player.vimeo.com/video/393795643",
                            "width": 1920,
                            "height": 1080
                        },
                        {
                            "uri": "/assets/images/pic-4.jpg",
                            "width": 1200,
                            "height": 900
                        },
                        {
                            "uri": "/assets/images/pic-5.jpg",
                            "width": 1200,
                            "height": 900
                        },
                        {
                            "uri": "/assets/images/video-2.mp4",
                            "width": 1920,
                            "height": 1080
                        },
                        {
                            "uri": "/assets/images/pic-6.jpg",
                            "width": 1200,
                            "height": 900
                        },
                        {
                            "uri": "/assets/images/pic-7.jpg",
                            "width": 1200,
                            "height": 900
                        },
                        {
                            "uri": "/assets/images/pic-8.jpg",
                            "width": 1200,
                            "height": 900
                        }
                    ]
                }
            },
            "b": {
                "seo": {
                    "title": "{{{ NAME }}} | Gallery B",
                    "meta_name": {},
                    "meta_property": {}
                },
                "content": {
                    "media": [
                        {
                            "uri": "https://player.vimeo.com/video/393795643",
                            "width": 1920,
                            "height": 1080
                        }
                    ]
                }
            },
            "c": {
                "seo": {
                    "title": "{{{ NAME }}} | Gallery C",
                    "meta_name": {},
                    "meta_property": {}
                },
                "content": {
                    "media": [
                        {
                            "uri": "/assets/images/pic-8.jpg",
                            "width": 1200,
                            "height": 900
                        }
                    ]
                }
            }
        },
        "nested": {
            "a": {
                "seo": {
                    "title": "{{{ NAME }}} | Nested A",
                    "meta_name": {},
                    "meta_property": {}
                },
                "content": {
                    "header": "Nested A hello world",
                    "body": "Ut atque praesentium aut assumenda cupiditate eos veniam internos. Eum eaque nihil ut dolor laudantium est voluptatem dolorem in molestiae ducimus vel iure sint. Quo eligendi voluptatem et magnam nihil non fugiat repudiandae et vitae dolores et quia recusandae qui quia consequatur. Qui ipsam nisi et autem consequatur cum autem sint est iure repudiandae et enim consequatur est nobis totam."
                }
            },
            "deep": {
                "b": {
                    "seo": {
                        "title": "{{{ NAME }}} | Nested Deep B",
                        "meta_name": {},
                        "meta_property": {}
                    },
                    "content": {
                        "header": "Nested Deep B hello world",
                        "body": "Ut atque praesentium aut assumenda cupiditate eos veniam internos. Eum eaque nihil ut dolor laudantium est voluptatem dolorem in molestiae ducimus vel iure sint. Quo eligendi voluptatem et magnam nihil non fugiat repudiandae et vitae dolores et quia recusandae qui quia consequatur. Qui ipsam nisi et autem consequatur cum autem sint est iure repudiandae et enim consequatur est nobis totam."
                    }
                }
            }
        }
    },
    "components": {
        "stats": {
            "content": "Hello world! stats"
        },
        "nested": {
            "content": "Hello world! nested"
        },
        "dev": {
            "header": "Dev Tools"
        }
    }
}